
#slider:not(.slider-swiper) {
    flex: 1 0 auto;
}
#slider:not(.slider-swiper) .fixed-content {
    text-shadow: 2px 2px 2px black;
}
@media (min-width: 992px) {
    /* 
        We ensure that when header is fixed, we have a minimum of 102px of space before the fixed content
        This trick does not break the vertical alignment centered in the page
    */
#slider:not(.slider-swiper) .fixed-content {
        padding-top:204px;
        transform: translate(0px, -102px);
}
}
#slider:not(.slider-swiper) .swiper-wrapper {
    position:absolute;
    top:0px;
}
#slider:not(.slider-swiper) .swiper-slide {
    background-size: cover;
    background-color: rgb(255, 255, 255);   
    background-repeat: no-repeat;
}
#slider:not(.slider-swiper) p {
    font-size: 1.5rem;
    letter-spacing: 1px;
}



